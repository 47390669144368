import { GET_DEX_DATA } from '../redux/types'
import globalConfig from "../global-config";

const INITIAL_STATE = {
  accumulatedSupply: Number(globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.accumulatedSupply),
  startingIndex: Number(globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.startingIndex),
  burnRewards: Number(globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.burnRewards),
  isSoldOut: Number(globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.isSoldOut),
  startingTimeStamp: Number(globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.startingTimeStamp),
  contractBalance: 0,
  burnRewardPoolPercentage: 0.7,
  finalRewardPoolSize: 0,
  currentBlockTimeStamp: Math.floor(Date.now()/1000)
}

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEX_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default homeReducer
