import React , { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTraits } from '../../redux/actions'
import NFTList from '../Global/NFTList';
import { getImageSrc } from "../../utils/functions";
import Button from '../Global/Button';
const INIT_FILTER = {
  background: [],
  skin: [],
  costume: [],
  eyes: [],
  item1: [],
  item2: []
}
const Gallary = ({ getTraits, isRevealed, traits, startingIndex }) => {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState(INIT_FILTER)
  const [dictionary, setDictionary] = useState({
    background: {},
    skin: {},
    costume: {},
    eyes: {},
    item1: {},
    item2: {}
  })

  useEffect(() => {
    setDictionary(buildDictionary(filterOn(traits)))
  }, [traits])

  const buildDictionary = (traits) => {
    const tmp = {
      background: {},
      skin: {},
      costume: {},
      eyes: {},
      item1: {},
      item2: {}
    };
    for (let trait of traits) {
      let { background, skin, costume, eyes, item1, item2 } = trait;
      background = background || 'None'
      tmp.background[background] = tmp.background[background] ? tmp.background[background] + 1 : 1;
      skin = skin || 'None'
      tmp.skin[skin] = tmp.skin[skin] ? tmp.skin[skin] + 1 : 1;
      costume = costume || 'None'
      tmp.costume[costume] = tmp.costume[costume] ? tmp.costume[costume] + 1 : 1;
      eyes = eyes || 'None'
      tmp.eyes[eyes] = tmp.eyes[eyes] ? tmp.eyes[eyes] + 1 : 1;
      item1 = item1 || 'None'
      tmp.item1[item1] = tmp.item1[item1] ? tmp.item1[item1] + 1 : 1;
      item2 = item2 || 'None'
      tmp.item2[item2] = tmp.item2[item2] ? tmp.item2[item2] + 1 : 1;
    }
    return tmp
  }

  useEffect(() => {
    getTraits()
    window.scrollTo(0, 0)
  }, [getTraits])

  useEffect(() => {
    const d = Object.keys(traits).map((tokenId)=> {
      const assetId = getImageSrc(tokenId, startingIndex).assetId - 1;
      return {tokenId: Number(tokenId), ...traits[assetId]}
    })
    setData(d)
  }, [traits, filter, startingIndex])

  const filterOn = (data) => {
    const d = data.filter(item=>{
      let { background, skin, costume, eyes, item1, item2, tokenId } = item;
      if(tokenId >= 4354) return false;
      background = background || 'None'
      skin = skin || 'None'
      costume = costume || 'None'
      eyes = eyes || 'None'
      item1 = item1 || 'None'
      item2 = item2 || 'None'
      let matchBackground = true;
      let matchSkin = true;
      let matchCostume = true;
      let matchEyes = true;
      let matchItem1 = true;
      let matchItem2 = true;
      if(filter.background.length>0) matchBackground = background === filter.background[0];
      if(filter.skin.length>0) matchSkin = skin === filter.skin[0];
      if(filter.costume.length>0) matchCostume = costume === filter.costume[0];
      if(filter.eyes.length>0) matchEyes = eyes === filter.eyes[0];
      if(filter.item1.length>0) matchItem1 = item1 === filter.item1[0];
      if(filter.item2.length>0) matchItem2 = item2 === filter.item2[0];
      return matchBackground && matchSkin && matchCostume && matchEyes && matchItem1 && matchItem2;
    });
    return d;
  }

  useEffect(()=>{
    setDictionary(buildDictionary(filterOn(data)))
  }, [data])

  useEffect(() => {
    const currentURL = window.location.href.split('?')[1];
    const params = new URLSearchParams(currentURL);
    let tmp = filter;
    params.forEach((val, key)=>{
      if (tmp[key]) tmp[key] = [val]
    })
    setFilter(tmp)
    setDictionary(buildDictionary(filterOn(data)));
  }, [filter])

  const applyFilter = (e) => {
    if(!e){
      setFilter(INIT_FILTER)
      window.location.href = `${window.location.origin}/#/gallery`;
      return;
    }
    e.preventDefault();
    let tmp = filter;
    if(e.target.value) {
      tmp[e.target.name] = [e.target.value]
    }else{
      tmp[e.target.name] = []
    }
    setFilter(tmp)
    const param = {}
    for(let f in tmp) {
      if(tmp[f].length > 0) param[f] = tmp[f][0]
    }
    const params = new URLSearchParams(param);
    window.location.replace(`${window.location.origin}/#/gallery?${params.toString()}`);
    window.location.reload(true)
  }

  const reset = () => {
    window.location.replace(`${window.location.origin}/#/gallery`);
    window.location.reload(true)
  }

  useEffect(() => {
    return function cleanUp () {
      setFilter(INIT_FILTER)
    }
  }, [setFilter])

  if(!isRevealed) return <div className='page-container'><h3>The arts are not revealed yet. Please check back later.</h3></div>;
  const finalData = filterOn(data);
  return <div className='page-container gallary-container'>
    <div>
      <h2 className='gallery-title'><span>Filter</span><Button onClick={reset}>Reset</Button></h2>
      <form>
        {Object.keys(dictionary).map(key=>(<div className={`filter-item active-${Boolean(filter[key][0])}`} key={key}>
          <div className='title'>{key.slice(0, 1).toUpperCase()}{key.slice(1)}</div>
          <select value={filter[key][0]||''} name={key} onChange={applyFilter}>
            <option value=''>All {key}</option>
            {Object.keys(dictionary[key]).sort().map((trait)=>(<option key={trait} value={trait}>{trait} ({dictionary[key][trait]})</option>))}
          </select>
        </div>))}
      </form>
    </div>
    <div>
      Result: {finalData.length}
    </div>
    {<NFTList data={finalData} />}
  </div>
}

const mapStateToProps = ({ home, gallery }) => {
  return {
    supply: home.accumulatedSupply,
    isRevealed: home.startingIndex !== 0,
    traits: gallery.traits.slice(0),
    startingIndex: home.startingIndex
  }
}

export default connect(mapStateToProps, { getTraits })(Gallary)
