import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getPastWalletEvent } from '../../redux/actions'
import globalConfig from '../../global-config'

const renderEvents = (events) => {
  return <div className='event-list'>
    <div className='event-item title'>
      <div className='blockBumber'>block</div>
      <div className='transactionHash'>txnHash</div>
      <div className='action'>event</div>
      <div className='balance'>balance</div>
    </div>
    {events.length>0 ? events.map((event, i)=>{
      return <div key={`${i}-${event.transactionHash}`} className='event-item'>
        <div className='blockBumber'>
          # {event.blockNumber}
        </div>
        <div className='transactionHash code'>
          <a target='_blank' rel='noopener noreferrer' href={`${globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].bscScan}/tx/${event.transactionHash}`}>{event.transactionHash}</a>
        </div>
        <div className='action'>
          {event.event}
        </div>
        <div className={`balance ${event.action === '+' ? 'green': 'red'}`}>
          {event.action} <span className='bignumber-wrapper'>{Number(event.amount).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span> {event.unit}
        </div>
      </div>
    }): <div className='event-item no-event'>
      no events
    </div>}
  </div>
}

const Events = ({ web3, getPastWalletEvent, events, account }) => {
  useEffect(() => {
    getPastWalletEvent(web3, account)
  }, [getPastWalletEvent, web3, account])
  return (
    <div className='event-list-container'>
      {renderEvents(events)}
    </div>
  )
}

const mapStateToProps = ({ blockchain, wallet }) => {
  return { web3: blockchain.web3, events: wallet.events }
}

export default connect(mapStateToProps, { getPastWalletEvent })(Events)
