import { combineReducers } from 'redux'
import blockchainReducer from './blockchainReducer'
import homeReducer from './homeReducer'
import walletReducer from './walletReducer'
import bbtReducer from './bbtReducer'
import galleryReducer from './galleryReducer'
import leaderReducer from './leaderReducer'

export default combineReducers({
  blockchain: blockchainReducer,
  home: homeReducer,
  wallet: walletReducer,
  BBT: bbtReducer,
  gallery: galleryReducer,
  leaderboard: leaderReducer
})
