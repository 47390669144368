import React, { useState } from 'react'
import Modal from '../Global/Modal'
import { connect } from 'react-redux'
import { mintBBT, getUserBalances } from '../../redux/actions'
import { toast } from 'react-toastify'
import Button from '../Global/Button'

const renderBuy = (amountLeft) => {
  const renArr = []
  const upper = Math.min(50, amountLeft)
  for (let i = 1; i <= upper; i++) {
    renArr.push(<option key={i} value={i}>{i}</option>)
  }
  return renArr
}
const BuyModal = ({ accumulatedSupply, mintBBT, web3, hideModal, getUserBalances, account, modalHeader = 'Collect More' }) => {
  const [currentAmt, setCurrentAmt] = useState(1)
  React.useEffect(()=>{
    if(17153-accumulatedSupply>50) setCurrentAmt(10);
  }, [accumulatedSupply])
  const handleBuy = () => {
    if (currentAmt > 0) {
      toast.info(`You request to buy ${currentAmt} BearNBear. Waiting for Binance Smart Chain to confirm.`)
      mintBBT(currentAmt, web3)
        .then(() => {
          getUserBalances(web3, account)
          hideModal()
        })
    } else {
      toast.error('You have to at least buy 1 BearNBear.')
    }
  }
  return (
    <Modal mode='primary' size='sm' modalHeader={modalHeader} hideModal={hideModal} clickToClose pressToClose>
      <div className='buy-input-container'>
        <select value={currentAmt} onChange={(e) => setCurrentAmt(e.target.value)}>
          {renderBuy(17153-accumulatedSupply)}
        </select>
        <span className='buy-modal-bear'>BearNBear</span>
        <div className='notes'>
          <small className='prompt'>Due to the limitation of blockchain/solidity to be specific.</small>
          <div className='prompt'>Maximum 50 BBTs per transaction, but you can always do more transactions get your desired amount of BearNBears.</div>
        </div>
      </div>
      <Button onClick={() => handleBuy()}>Confirm</Button>
    </Modal>
  )
}

const mapStateToProps = ({ blockchain, home }) => {
  return { web3: blockchain.web3, account: blockchain.account, accumulatedSupply: home.accumulatedSupply  }
}

export default connect(mapStateToProps, { mintBBT, getUserBalances })(BuyModal)
