import Blockies from "react-blockies";
import React from "react";

const BlockiesComponent = ({address, customClass = ''}) => {
  return <Blockies
    seed={address}
    size={10}
    scale={10}
    // color="#dfe"
    bgColor="#F0B90B"
    spotColor="#fff"
    className={`address-profile-blockies ${address} ${customClass}`}
  />
}

export default BlockiesComponent;
