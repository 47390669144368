import React, { useEffect, useState } from 'react'
import content from '../content.json'
import Image from "../Global/ImageWithLoader";
const length = 4;

const BearNBear = () => {
  const [startIndex, setStartIndex] = useState(0)
  useEffect(()=>{
    window.scrollTo(0, 0)
    const startIndex = Math.floor(Math.random() * length);
    setStartIndex(startIndex)
  }, [])

  const renderGallery = () => {
    const arr = Array.from(Array(length).keys());
    return arr.map(i=>{
      return <img key={i} alt={`example-${i}`} src={require(`./EmbeddedGallery/${(i + startIndex) % length}-preview.png`).default} />
    })
  }
  return (
    <div>
      <div className='bbt-gallery no-scroll' style={{'marginTop': '100px'}}>
        {renderGallery()}
      </div>
      <div className='page-container' style={{'marginTop': '0px'}}>
        <div>
          <h2>You own the lifecycle of the BearNBear(BBT)</h2>
          <p>{content.BBTConcepts}</p>
        </div>
        <div>
          <h2 id='timeline'>How it works</h2>
          {content.overview.AboutBBT.map((w, i) => <p key={i}>{w}</p>)}
          <Image alt='timeline' style={{ width: '100%' }} src={require('../../assets/images/timeline.svg').default} />
        </div>
        <div>
          <h2 id='reward-pool'>Burn BearNBear to get rewards in BNB token</h2>
          <p>{content.overview.Reward}</p>
          <Image alt='decay' style={{ width: '100%' }} src={require('../../assets/images/decay.svg').default} />
        </div>
        <div>
          <h2>You are part of the Art, the Art is part of you.</h2>
          <p>{content.overview.AboutmBT.utilityOfmBT}</p>
        </div>
        <div>
          <h2>Community Incentives</h2>
          <p>{content.overview.AboutmBT.communityIncentives}</p>
          <Image className='traits-bear' src={require('../../assets/images/mbt-speed.svg').default} alt='mbt-speed' />
          <p>{content.overview.AboutmBT.mBTDistribution}</p>
        </div>
        <div>
          <h2 id='traits'>Arts & Traits</h2>
          <p>{content.overview.traits[0]}</p>
          <Image className='traits-bear' src={require('../../assets/images/traits-bear-1.png').default} alt='traits-bear-1' />
          <p>{content.overview.traits[1]}</p>
          <Image className='traits-bear' src={require('../../assets/images/traits-bear-2.png').default} alt='traits-bear-2' />
          <p>{content.overview.traits[2]}</p>
          <p>{content.overview.traits[3]}</p>
        </div>
      </div>
    </div>
  )
}

export default BearNBear
