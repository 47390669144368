import React from 'react'
import { connect } from 'react-redux'
import history from '../../../utils/history'
import Image, { CircleLoader } from '../ImageWithLoader'
import { getImageSrc } from '../../../utils/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BBT = ({ index, isRevealed, name, startingIndex }) => {
  const renderImage = (isRevealed) => {
    const imageSrc = getImageSrc(index, startingIndex);
    if (isRevealed) {
      return (
        <div className='gallary-frame revealed' onClick={() => history.push(`/bbt/${index}`)}>
          <div className='overlay'>
            <div className='code index'>
              #{index}
              {(name && name.length>0) && <span>{name}</span>}
            </div>
          </div>
          <LazyLoadImage
            visibleByDefault={true}
            // effect="blur"
            placeholder={<CircleLoader />}
            threshold={100}
            alt={`bearNbear-${imageSrc.assetId}`}
            src={imageSrc.small} />
          {/*<Image src={imageSrc.small} alt={`bearNbear-${imageSrc.assetId}`} />*/}
        </div>)
    }
    return <div className='gallary-frame unrevealed'>
      <div className='overlay'>
        <div className='code index'>
          #{index}
          <span>Not revealed yet</span>
        </div>
      </div>
      <Image src={require('../../../assets/images/unrevealed-envolope.png').default} alt='unrevealed-envolope' />
    </div>
  }
  return (<>
    {renderImage(isRevealed)}
  </>)
}

const mapStateToProps = ({ home }) => {
  return {
    isRevealed: home.startingIndex !== 0,
    startingIndex: home.startingIndex
  }
}

export default connect(mapStateToProps, null)(BBT)
