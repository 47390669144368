import { GET_CURRENT_BBT_DETAILS } from '../redux/types'

const INITIAL_STATE = [{
  name: '',
  description: '',
  tokanId: 0,
  ownerAddress: 0,
  accumulated: 0
}]

const bbtReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_BBT_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
}

export default bbtReducer
