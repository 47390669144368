import { GET_REF_EVENTS } from '../redux/types'

const INITIAL_STATE = {
  ranks: []
}

const galleryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REF_EVENTS:
      console.log('payload', action.payload)
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default galleryReducer
