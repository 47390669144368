import privateConfig from './private-config.json';

const isRevealed =  Math.floor(Date.now()/1000) > 1622862000;

const globalConfig = {
  DEFAULT_GAS: '8500000',
  DEFAULT_GAS_PRICE: '5000000000',
  bsc: {
    main: {
      bscScan: 'https://bscscan.com',
      rpcEndpoint: 'https://bsc-dataseed.binance.org/',
      bearNBearTokenContractAddress: '0xbD8724Ec4F1Bbf98ce0db8612dE67E0a192F798d',
      miniBearTokenContractAddress: '0xA5eb55A316aA589dc09ea63C7d53F6Ba44e23a21',
      contractInfo: {
        startingIndex: isRevealed ? 13218 : 0,
        accumulatedSupply: 0,
        burnRewards: 89944535743631881,
        isSoldOut: false,
        startingTimeStamp: 1617678000
      },
      chainConfig: {
        chainId: '0x38', // A 0x-prefixed hexadecimal string --> 56
        chainName: 'Smart Chain - Mainnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB', // 2-6 characters long
          decimals: 18
        },
        rpcUrls:['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/']
      }
    },
    testnet: {
      bscScan: 'https://testnet.bscscan.com',
      rpcEndpoint: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      bearNBearTokenContractAddress: '0x96A960798C61c44B2FA3dB180693Bd88Ff89dAC2', // beta
      miniBearTokenContractAddress: '0x09D23a563d06Bb8a07FccA2CCeb7EEf72649416f', // beta
      // bearNBearTokenContractAddress: '0xd1924Eec2D98E00f8Ed12106380d4F38D8Ab383e', // alpha
      // miniBearTokenContractAddress: '0x3a9AAD959e8230A7cf3a8D29e3A2C2629f6981fb', // alpha
      contractInfo: {
        // startingIndex: isRevealed ? 13218 : 0,
        startingIndex: 13218,
        accumulatedSupply: 0,
        burnRewards: 89944535743631881,
        isSoldOut: false,
        startingTimeStamp: 1617252600 // Mar 31 9:50pm PDT testing time // beta
      },
      chainConfig: {
        chainId: '0x61', // 97
          chainName: 'Smart Chain - Testnet',
          nativeCurrency: {
          name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls:['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com']
      },
    },
    private: privateConfig
  },
}

export default globalConfig
