import React from 'react'
import globalConfig from '../../global-config'
import Countdown from 'react-countdown'
import Button from '../Global/Button'
import './hero/style.scss'
const startingTimeStampInSec = globalConfig.bsc[process.env.REACT_APP_DEFAULT_NETWORK].contractInfo.startingTimeStamp

const CountDownComponent = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div onClick={window.location.reload(true)}><Button>Refresh page</Button></div>
    } else {
      return (<div>
        <h1>Crowdsale starts in</h1>
        <span className='countdown'>{days}d {hours}h {minutes}m {seconds}s</span>
      </div>)
    }
  };
  return (
    <div className='coming-soon'>
      <Countdown date={startingTimeStampInSec * 1000} renderer={renderer} />
      {/*<div title="Add to Calendar" className="addeventatc">*/}
      {/*  Remind me on Calendar*/}
      {/*  <span className="start">04/5/2021 07:50 PM</span>*/}
      {/*  <span className="organizer">BearNBear Team</span>*/}
      {/*  <span className="organizer_email">team@thebearnbear.com</span>*/}
      {/*  <span className="timezone">America/Los_Angeles</span>*/}
      {/*  <span className="title">[BearNBear] Crowdsale Starts</span>*/}
      {/*  <span className="description">BearNBear Crowdsale Starts 🎉*/}
      {/*    \n\n*/}
      {/*    Prepare your BNB(BEP20, Binance Coin on smart chain) \n*/}
      {/*    Visit https://thebearnbear.com to own the first deflationary NFT in the history and join the the community of the largest public collaborative art.*/}
      {/*    \n\n\n*/}
      {/*    Twitter: https://twitter.com/thebearnbear \n*/}
      {/*    Blog: https://medium.com/@TheBearNBear \n*/}
      {/*  </span>*/}
      {/*  <span className="location">https://thebearnbear.com</span>*/}
      {/*  <span className="alarm_reminder">{60*60*24}</span>*/}
      {/*  <span className="alarm_reminder">30</span>*/}
      {/*  <span className="alarm_reminder">5</span>*/}
      {/*</div>*/}
    </div>
  )
}

export default CountDownComponent;
