import React from 'react'
import { NavLink } from 'react-router-dom'
import {connect} from "react-redux";

const navItems = [
  // {
  //   name: 'crowdsale',
  //   tag: 'Crowdsale',
  //   route: '/crowdsale',
  // },
  {
    name: 'overview',
    tag: 'Overview',
    route: '/overview'
  },
  {
    name: 'mBTToken',
    tag: 'miniBear',
    route: '/mBT'
  },
  {
    name: 'Gallery',
    tag: 'Gallery',
    route: '/gallery'
  },
  // {
  //   name: 'leaderboard',
  //   tag: 'Leaderboard',
  //   route: '/leaderboard',
  //   new: true
  // },
]

const renderNavItems = (itemArr) => {
  return itemArr.map((item) => {
    return (
      <NavLink
        key={item.route}
        to={item.route}
        className={`item ${item.name}`}
        activeClassName='active'
      >
        {item.new && <span className='new'>
        </span> }
        {item.tag}
      </NavLink>
    )
  })
}

const Navbar = ({account}) => {
  return (
    <div className='navbar-fixed-top'>
    <div className='navbar-component'>
      <div className='navbar-container'>
        <a href='/' className='item site-title'>
          <img alt='logo' src={require('./logo.svg').default}/>
          BearNBear
        </a>
         <NavLink to='/collection' className='item myWallet' activeClassName='active'>{account.length > 0 ? account.slice(0,5)+'...'+account.slice(account.length-5,account.length-1) : 'My Wallet'}</NavLink>
      </div>
      <div className='navbar-container'>
        {renderNavItems(navItems)}
         <NavLink to={`/collection/${account}`} className={`item myWallet ${account.length > 0}`} activeClassName='active'>{account.length > 0 ? account.slice(0,5)+'...'+account.slice(account.length-5,account.length-1) : 'My Wallet'}</NavLink>
      </div>
    </div>
    </div>
  )
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account
  }
}

export default connect(mapStateToProps, null)(Navbar)

