import React, { useEffect } from 'react'
import content from '../content.json'
import Image from '../Global/ImageWithLoader'

const Index = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='page-container'>
      <div>
        <h2>What is miniBear Token (mBT)?</h2>
        <p>{content.mBTToken.description1}</p>
        <ol>{content.mBTToken.description1Points.map(r => <li key={r}>{r}</li>)}</ol>
        <p>{content.mBTToken['description1-2']}</p>
        <p>{content.mBTToken.description2}</p>
        <Image alt='mBT-contract' src={require('../../assets/images/mBT-contract.svg').default} style={{ width: '100%' }} />
      </div>
      <div>
        <h2>The Rule of Names</h2>
        <ul>{content.mBTToken.theRulesOfNaming.map(r => <li key={r}>{r}</li>)}</ul>
      </div>
      <div>
        <h2>The Rule for Descriptions</h2>
        <ul>{content.mBTToken.theRulesOfDescription.map(r => <li key={r}>{r}</li>)}</ul>
      </div>
    </div>
  )
}

export default Index
