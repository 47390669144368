export const INIT_BLOCKCHAIN_ENVIRONMENT = 'INIT_BLOCKCHAIN_ENVIRONMENT'
export const CONNECT_WALLET = 'CONNECT_WALLET'
export const GET_SUPPLY = 'GET_SUPPLY'
export const GET_STARTING_INDEX = 'GET_STARTING_INDEX'
export const GET_WALLET_EVENTS = 'GET_WALLET_EVENTS'
export const GET_USER_BALANCES = 'GET_USER_BALANCES'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_CURRENT_BBT_DETAILS = 'GET_CURRENT_BBT_DETAILS'
export const GET_TRAITS = 'GET_TRAITS'
export const GET_DEX_DATA = 'GET_DEX_DATA'
export const GET_REF_EVENTS = 'GET_REF_EVENTS'
