import React from 'react'

const Footer = () => {
  return (
    <div className='footer-container'>
      <div>
        build with &#x2764;	on <a href='https://www.binance.org/en/smartChain' target='_blank' rel='noopener noreferrer'>Binance Smart Chain</a>
      </div>
      <div className='links'>
        Team BearNBear
        <a className='social twitter' href='https://twitter.com/thebearnbear' target='_blank' rel='noopener noreferrer'>Twitter</a>
        <a className='social medium' href='https://medium.com/@TheBearNBear' target='_blank' rel='noopener noreferrer'>Medium</a>
        <a className='social discord' href='https://discord.gg/nDsrYratep' target='_blank' rel='noopener noreferrer'>Discord</a>
        {/*<a className='social discord' href='https://t.me/BearNBear' target='_blank' rel='noopener noreferrer'>Telegram</a>*/}
      </div>
    </div>
  )
}

export default Footer
