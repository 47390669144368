import globalConfig from '../global-config'
import bearNBear from './contracts/BearNBearToken.json'
import miniBear from './contracts/MiniBearToken.json'
import { calculateTotalPrice } from '../utils/functions'
import web3instance from './web3'
import { toast } from 'react-toastify'
import axios from 'axios'
const EventEmitter = require('events').EventEmitter;

const network = process.env.REACT_APP_DEFAULT_NETWORK
const connector = {
  mintBBT: async (amount, web3) => {
    return new Promise(async (resolve, reject) => {
      web3 = web3instance(web3)
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      const provider = window.ethereum
      await provider.request({
        method: 'eth_requestAccounts'
      })
      const accounts = await web3.eth.getAccounts();
      console.log('account', accounts)
      // get current supply and price
      const totalSupply = await bearNBearInstance.methods.accumulatedSupply().call()
      // calculate price
      const totalPrice = calculateTotalPrice(Number(amount), Number(totalSupply.toString()))
      const totalPriceInWei = await web3.utils.toWei(totalPrice.toString(), 'ether')
      // 1 -> 330978
      // 5 -> 1343604
      // 10 -> 2609389
      // 20 -> 5140963
      // 30 -> 7672542
      // 40 -> 10204126
      // 50 -> 12735715
      let gasAmount = 330978 + Math.ceil(Number(amount-1)/5) * 1300000;
      if(totalSupply > 17150) gasAmount = 1300000;
      toast.error(accounts)
      if(accounts[0]) {
        return bearNBearInstance.methods
          .mintBBT(amount)
          .send({ from: accounts[0], gas: gasAmount, value: totalPriceInWei, gasPrice: '5000000000' })
          .on('transactionHash', function (transactionHash) {
            // call api to save events
            const body = {
              refererAddress: localStorage.getItem('refererAddress') || 0,
              buyerAddress: accounts[0],
              txnHash: transactionHash,
              BBTAmount: Number(amount),
              BNBAmount: totalPrice
            }
            const { startingIndex } = globalConfig.bsc[network].contractInfo;
            if(startingIndex === 0) {
              axios.post(`${process.env.REACT_APP_API_URL}/event`, body )
                .then((res) => console.log('res',res))
                .catch(err => console.log('error on saving event', err))
            }
          })
          .on('receipt', function (receipt) {
            console.log('receipt', receipt)
            toast.success(`Successfully minted ${amount} BBT with ${totalPrice} BNB`)
            resolve(receipt)
          })
          .on('error', function (err) {
            toast.error(`Failed to mint ${amount} BBT.`)
            console.log('error', err)
            reject(err)
          })
      }
    })
  },
  getTokenName: async (web3, tokenId) => {
    web3 = web3instance(web3)
    const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
    const name = await bearNBearInstance.methods.tokenNameByIndex(tokenId).call();
    return name;
  },
  getUserBalances: async (web3, userAddress) => {
    web3 = web3instance(web3)
    const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
    const miniBearInstance = new web3.eth.Contract(miniBear.abi, globalConfig.bsc[network].miniBearTokenContractAddress)
    const BBTBalance = await bearNBearInstance.methods.balanceOf(userAddress).call()
    const mBTBalance = web3.utils.fromWei(await miniBearInstance.methods.balanceOf(userAddress).call(), 'ether')
    let BNBBalance = await web3.eth.getBalance(userAddress)
    BNBBalance = web3.utils.fromWei(BNBBalance, 'ether');
    const BBTCollection = []
    const upper = Math.min(12, BBTBalance)
    for (let i = 0; i < upper; i++) {
      // const startingIndex = await bearNBearInstance.methods.startingIndex().call()
      const tokenId = await bearNBearInstance.methods.tokenOfOwnerByIndex(userAddress, i).call()
      BBTCollection.push({tokenId})
    }
    return {
      BBTCollection,
      BBTBalance,
      BNBBalance,
      mBTBalance
    }
  },
  getUserBalancesMore: async (web3, userAddress) => {
    web3 = web3instance(web3)
    const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
    const BBTBalance = await bearNBearInstance.methods.balanceOf(userAddress).call()
    const BBTCollection = []
    for (let i = 0; i < BBTBalance; i++) {
      // const startingIndex = await bearNBearInstance.methods.startingIndex().call()
      const tokenId = await bearNBearInstance.methods.tokenOfOwnerByIndex(userAddress, i).call()
      BBTCollection.push({tokenId})
    }
    return {
      BBTCollection
    }
  },
  getPastWalletEvent: (web3, account='', from = 0, to = 'latest') => {
    return new Promise(async (resolve, reject) => {
      try {
        web3 = web3instance(web3)
        const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
        const miniBearInstance = new web3.eth.Contract(miniBear.abi, globalConfig.bsc[network].miniBearTokenContractAddress)
        const ACTIONS = {
          MintBBT: '+',
          BurnBBT: '-',
          BnbRewards: '+',
          ClaimMBT: '+',
          BurnMBT: '-',
        }
        const AMOUNT = {
          MintBBT: ()=>1,
          BurnBBT: ()=>1,
          BnbRewards: (context)=>web3.utils.fromWei(context.bnbAmount, 'ether'),
          ClaimMBT: (context)=>web3.utils.fromWei(context.value, 'ether'),
          BurnMBT: (context)=>web3.utils.fromWei(context.value, 'ether')
        }
        const UNITS = {
          MintBBT: 'BBT',
          BurnBBT: 'BBT',
          BnbRewards: 'BNB',
          ClaimMBT: 'mBT',
          BurnMBT: 'mBT',
        }
        const EVENTS = {
          MintBBT: (context)=>`Mint BBT #${context.tokenId}`,
          BurnBBT: (context)=>`Burn BBT #${context.tokenId}`,
          BnbRewards: ()=>'Burning rewards',
          ClaimMBT: ()=>'Claim mBT',
          BurnMBT: ()=>`Spent mBT`,
        }
        const overWriteEventName = (events, eventName) => {
          const newArr = events ? events.map (evt => {
            evt.event = eventName
            return evt
          }): []
          return newArr;
        }
        const cleanUpEvents = (events) => {
          return events.map(evt => {
            const type = evt.event;
            const context = evt.returnValues;
            return {
              address: evt.address,
              transactionHash: evt.transactionHash,
              blockNumber: evt.blockNumber,
              action: ACTIONS[type],
              unit: UNITS[type],
              event: EVENTS[type](context),
              amount: AMOUNT[type](context),
            }
          }).sort((a,b)=>b.blockNumber - a.blockNumber)
        }
        const accounts = [account]
        const options = {
          fromBlock: from,
          toBlock: to,
          filter: {}
        }
        bearNBearInstance.getPastEvents('NameChange', { ...options, filter: {}}, (err, evts) => {});
        const history = []
        if (bearNBearInstance && miniBearInstance) {
          bearNBearInstance
            .getPastEvents('Transfer', { ...options, filter: { from: 0, to: accounts[0] } },
              (err, evts) =>
                history.push(...overWriteEventName(evts, 'MintBBT')))
            .then(() =>
              bearNBearInstance.getPastEvents('Transfer', { ...options, filter: {from: accounts[0], to: 0 } },
                (err, evts) =>
                  history.push(...overWriteEventName(evts, 'BurnBBT')))
            )
            .then(() =>
              bearNBearInstance.getPastEvents('BnbRewards', { ...options, filter: { user: accounts[0] } },
                (err, evts) =>
                  history.push(...overWriteEventName(evts, 'BnbRewards')))
            )
            .then(() =>
              miniBearInstance.getPastEvents('Transfer', { ...options, filter: { from: 0, to: accounts[0] } },
                (err, evts) =>
                  history.push(...overWriteEventName(evts, 'ClaimMBT')))
            )
            .then(() =>
              miniBearInstance.getPastEvents('Transfer', { ...options, filter: {from: accounts[0], to: 0 } },
                (err, evts) =>
                  history.push(...overWriteEventName(evts, 'BurnMBT')))
            )
            .then(() => {
              if(history.length===0) resolve([])
              return resolve(cleanUpEvents(history))
            })
            .catch(console.error)
        } else {
          return resolve([])
        }
      } catch (err) {
        console.log(err)
      }
    })
  },
  claimMbt: async (web3, indexArr) => {
    return new Promise(async (resolve, reject) => {
      web3 = web3instance(web3)
      const miniBearInstance = new web3.eth.Contract(miniBear.abi, globalConfig.bsc[network].miniBearTokenContractAddress)
      const accounts = await web3.eth.getAccounts()
      return miniBearInstance.methods
        .claim(indexArr)
        .send({ from: accounts[0], gasPrice: '5000000000', gasLimit: 200000+80000*indexArr.length })
        .on('receipt', async (receipt) => {
          const claimedAmountInWei = receipt.events.Transfer.returnValues.value
          const claimedAmount = await web3.utils.fromWei(claimedAmountInWei, 'ether')
          toast.success(`Successfully claimed ${claimedAmount} mBTs.`)
          resolve(receipt)
        })
        .on('error', function (err) {
          toast.error('Failed to claim all mBTs.')
          console.log('error', err)
          reject(err)
        })
    })
  },
  changeName: async (web3, index, value) => {
    return new Promise(async (resolve, reject) => {
      web3 = web3instance(web3)
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      const accounts = await web3.eth.getAccounts()
      return bearNBearInstance.methods
        .changeName(index, value)
        .send({ from: accounts[0], gasPrice: '5000000000' })
        .on('receipt', function (receipt) {
          resolve(receipt)
          toast.success('Successfully changed the name.')
        })
        .on('error', function (err) {
          console.log('error', err)
          toast.error('Failed to change the name.')
          reject(err)
        })
    })
  },
  changeDescription: async (web3, index, value) => {
    return new Promise(async (resolve, reject) => {
      web3 = web3instance(web3)
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      const accounts = await web3.eth.getAccounts()
      return bearNBearInstance.methods
        .changeDescription(index, value)
        .send({ from: accounts[0], gasPrice: '5000000000' })
        .on('receipt', function (receipt) {
          toast.success('Successfully changed the description.')
          resolve(receipt)
        })
        .on('error', function (err) {
          toast.error('Failed to change the description.')
          console.log('error', err)
          reject(err)
        })
    })
  },
  getBBTDetails: (web3, tokenId) => {
    return new Promise(async (resolve, reject)=>{
      try {
        web3 = web3instance(web3)
        const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
        const miniBearInstance = new web3.eth.Contract(miniBear.abi, globalConfig.bsc[network].miniBearTokenContractAddress)
        bearNBearInstance.methods.ownerOf(tokenId).call()
          .then(async (ownerAddress) => {
            const name = await bearNBearInstance.methods.tokenNameByIndex(tokenId).call()
            const description = await bearNBearInstance.methods.tokenDescriptionByIndex(tokenId).call()
            const accumulatedInWei = await miniBearInstance.methods.getAccumulated(tokenId).call()
            const accumulated = await web3.utils.fromWei(accumulatedInWei, 'ether')
            return resolve({ name, description, tokenId, ownerAddress, accumulated })
          })
          .catch((err)=>{
            return resolve({ tokenId, ownerAddress: 0 })
          })
      } catch (e) {
        reject(e)
      }
    })
  },
  getChangeLog: async (web3, index) => {
    return new Promise (async (resolve, reject) => {
      web3 = web3instance(web3)
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      const filter = {
        fromBlock: 0,
        toBlock: 'latest',
        filter: {
        }
      }
      const history = []
      if (bearNBearInstance) {
        bearNBearInstance
          .getPastEvents('NameChange', filter, (err, evts) => {
            console.log('namechange err', err)
            if (evts && evts.length > 0) {
              history.push(...evts)
            }
          })
          .then(() => {
            bearNBearInstance.getPastEvents('DescriptionChange', filter, (err, evts) => {
              console.log(err)
              if (evts.length > 0) {
                history.push(...evts)
              }
            })
          })
          .then(() => history)
      }
    })
  },
  burnBbt: async (web3, index) => {
    return new Promise (async (resolve, reject) => {
      web3 = web3instance(web3)
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      const accounts = await web3.eth.getAccounts()
      return bearNBearInstance.methods
        .burn(index)
        .send({ from: accounts[0], gasPrice: '5000000000' })
        .on('receipt', function (receipt) {
          toast.success('Successfully burnt the BearNBear.')
          resolve(receipt)
          window.location.reload();
        })
        .on('error', function (err) {
          toast.error('Failed burn the BearNBear.')
          console.log('error', err)
          reject(err)
        })
    })
  },
  getDexData: async (web3) => {
    // getContractBalance(res.web3)
    try {
      web3 = web3instance(web3)
      const contractBalanceInWei = await web3.eth.getBalance(globalConfig.bsc[network].bearNBearTokenContractAddress)
      const contractBalance = Number(web3.utils.fromWei(contractBalanceInWei, 'ether'))
      const bearNBearInstance = new web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
      // const burnRewardPoolPercentageInWei = await bearNBearInstance.methods.getBurnRewardPoolSize().call()
      // const burnRewardPoolPercentage = Number(web3.utils.fromWei(burnRewardPoolPercentageInWei, 'ether'))
      const accumulatedSupply = await bearNBearInstance.methods.accumulatedSupply().call()
      const burnRewardsInWei = await bearNBearInstance.methods.burnRewards().call()
      const burnRewards = web3.utils.fromWei(burnRewardsInWei, 'ether')
      const finalRewardPoolSizeInWei = await bearNBearInstance.methods.rewardPoolSize().call()
      const finalRewardPoolSize = web3.utils.fromWei(finalRewardPoolSizeInWei, 'ether')
      const blockNumber = await web3.eth.getBlockNumber();
      const currentBlock = await web3.eth.getBlock(blockNumber);
      const totalSupply = await bearNBearInstance.methods.totalSupply().call()
      const burnCount = accumulatedSupply-totalSupply;
      return {
        contractBalance,
        // burnRewardPoolPercentage,
        accumulatedSupply: Number(accumulatedSupply),
        burnCount: Number(burnCount),
        burnRewards: Number(burnRewards),
        finalRewardPoolSize: Number(finalRewardPoolSize),
        currentBlockTimeStamp: currentBlock.timestamp
      }
    } catch (err) {
      console.log('getDexData err', err)
    }
  }
}

export class GetUserBalancesMoreOnDemand extends EventEmitter {
  constructor(web3) {
    super()
    this.web3 = web3;
  }
  async subscribe(userAddress) {
    const bearNBearInstance = new this.web3.eth.Contract(bearNBear.abi, globalConfig.bsc[network].bearNBearTokenContractAddress)
    const BBTBalance = await bearNBearInstance.methods.balanceOf(userAddress).call()
    const BBTCollection = []
    for (let i = 0; i < BBTBalance; i++) {
      const tokenId = await bearNBearInstance.methods.tokenOfOwnerByIndex(userAddress, i).call()
      BBTCollection.push({tokenId})
      this.emit('newItem', BBTCollection)
    }
  }
}

export default connector

// web3.eth.balanceOf('BBTcontractAddress) --> get current all BNB collected

// .method.burnRewardPool -- > get current reward Pool
