import React from 'react'
import Router from './Router'
import Navbar from './components/Navbar'
import AnnouncementBanner from './components/AnnouncementBanner'
import { connect } from 'react-redux'
import { initBlockchainEnvironment, getDexData } from './redux/actions'
import Footer from './components/Footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { calculateTotalPrice } from './utils/functions'
import qs from 'qs'
let interval
class App extends React.Component {

  componentDidMount () {
    // init blockchain environment
    console.log('link', process.env.REACT_APP_API_URL)
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    console.log('query', query)
    if (query && query.r) {
      localStorage.setItem('refererAddress', query.r)
    }
    this.props.initBlockchainEnvironment()
      .then((res) => {
        this.props.getDexData(res.web3)
        // interval = setInterval(() => {
        //   this.props.getDexData(res.web3)
        // }, 10000)
      })
  }

  componentWillUnmount () {
    clearInterval(interval)
  }

  render () {
    return (
      <>
        <AnnouncementBanner />
        <Navbar />
        <Router />
        <Footer />
        <ToastContainer position='bottom-right' />
      </>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return { web3: blockchain.web3 }
}

export default connect(mapStateToProps, { initBlockchainEnvironment, getDexData })(App)
