import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getRefEvents } from '../../redux/actions'
import { CircleLoader } from '../Global/ImageWithLoader'
const Leaderboard = ({ getRefEvents, ranks }) => {
  const [ loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getRefEvents()
      .then(() => {
        setLoading(false)
      })
  }, [getRefEvents])

  useEffect(() => {
    const interval = setInterval(() => {
      getRefEvents()
    }, 5000)
    return function cleanUp () {
      clearInterval(interval)
    }
  })
  const renderRank = () => {
    return ranks.map((d, i) => {
      return (
        <div className='event-item' key={d.txnHash}>
          <div className='rank'>{i + 1}</div>
          {/*<div className='refererAddress'>{d.refererAddress.slice(0,6)+ '***'+ d.refererAddress.slice(d.refererAddress.length - 6, d.refererAddress.length - 1 )}</div>*/}
          <div className='refererAddress'>{d.refererAddress}</div>
          <div className='BNBBonus'>{Number(d.BNBBonus).toFixed(3)} BNB ( ≈ ${Number(d.BNBBonusInUSD).toFixed(2)})</div>
        </div>
      )
    })
  }
  if (loading) {
    return (
      <div className='page-container'>
        <CircleLoader />
      </div>
    )
  }
  return (
    <div className='page-container'>
      <h2>Referral Leaderboard <a href='https://thebearnbear.medium.com/bearnbear-launches-referral-program-1f06d5127e51?source=social.tw' target='_blank' rel='noopener noreferrer' className='leaderboard-note'>How to participate?</a></h2>
      <div className='event-list-container'>
        <div className='event-list'>
          <div className='event-item title'>
            <div className='rank'>#</div>
            <div className='refererAddress'>Referer</div>
            <div className='BNBBonus'>Referral Bonus(USD equivalent)</div>
          </div>
          {ranks.length > 0 ? renderRank() : <div>No events</div>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ leaderboard }) => {
  return {
    ranks: leaderboard.ranks
  }
}

export default connect(mapStateToProps, { getRefEvents })(Leaderboard)
