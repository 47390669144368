import React, { useState } from 'react'
import GlobalModal from '../Global/Modal'
import Button from '../Global/Button'
import {changeName, changeDescription, burnBbt, getBBTDetails} from '../../redux/actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

const Modal = ({ burnRewards, modalMode, hideModal, tokenId, web3, changeName, changeDescription, mBTBalance, burnBbt, getBBTDetails }) => {
  const [value, setValue] = useState('')
  const [loadingChangingName, setLoadingChangingName] = useState(false)
  const [loadingChangingDescription, setLoadingChangingDescription] = useState(false)
  const [loadingBuring, setLoadingBuring] = useState(false)
  const MODES = {
    'Change Name': {
      submitLogic: (regex, localValue) => {
        if (Number(mBTBalance) < 2000) {
          toast.error('You don\'t have enough mBTs.')
          return;
        }
        if (localValue.length === 0) {
          toast.error('Name cannot be empty.')
        } else if (localValue.length > 25) {
          toast.error('Name cannot be over 25 symbols(including spaces).')
        } else if (regex.test(localValue)) {
          toast.error('Only alphanumerical symbols and spaces are eligible for use')
        } else {
          toast.info('You request to change the name of BearNBear. Waiting for Binance Smart Chain to confirm.')
          setLoadingChangingName(true)
          changeName(web3, tokenId, localValue)
            .then((res) => {
              setLoadingChangingName(false)
              if (res === 'success') hideModal()
            })
        }
      },
      component: <GlobalModal clickToClose={!loadingChangingName} modalHeader={modalMode} size='sm' mode='primary' hideModal={hideModal}>
        <div>
          <div className='prompt'>
            <div>By changing the name,
              <br/>you define the name of this art. The record will be stored on blockchain and stay with this
              art forever.
            </div>
          </div>
          <div className='input-length'>{value.length}/25 characters</div>
          <input maxLength={25} autoFocus type='text' value={value} onChange={(e) => setValue(e.target.value)}/>
          <div className='prompt grey'>
            <div>* Changing name costs <span className='code'>2000 mBT</span> and it cannot be reverted</div>
          </div>
          <Button loading={loadingChangingName} onClick={(e) => handleSubmit(e)}>Confirm</Button>
        </div>
      </GlobalModal>
    },
    'Change Description': {
      submitLogic: (regex, localValue) => {
        if (Number(mBTBalance) < 2000) {
          toast.error('You don\'t have enough mBTs.')
          return;
        }
        if (localValue.length === 0) {
          toast.error('Description cannot be empty.')
        } else if (localValue.length > 250) {
          toast.error('Description cannot be over 250 symbols(including spaces).')
        } else {
          toast.info('You request to change the description of BearNBear. Waiting for Binance Smart Chain to confirm.')
          setLoadingChangingDescription(true)
          changeDescription(web3, tokenId, localValue)
            .then((res) => {
              setLoadingChangingDescription(false)
              if (res === 'success') hideModal()
            })
        }
      },
      component: <GlobalModal clickToClose={!loadingChangingDescription} modalHeader={modalMode} size='sm' mode='primary' hideModal={hideModal}>
        <div>
          <div className='prompt'>
            <div>By changing the description, <br />
              you define the elaboration of this art. The record will be stored on blockchain and stay with this
              art forever.
            </div>
          </div>
          <div className='input-length'>{value.length}/250 characters</div>
          <textarea rows={8} maxLength={250} autoFocus value={value} onChange={(e) => setValue(e.target.value)}/>
          <div className='prompt grey'>
            <div>* Changing description costs <span className='code'>2000 mBT</span> and it cannot be reverted</div>
          </div>
          <Button loading={loadingChangingDescription} onClick={(e) => handleSubmit(e)}>Confirm</Button>
        </div>
      </GlobalModal>
    },
    'Burn': {
      submitLogic: () => {
        if (Number(mBTBalance) < 24000) {
          toast.error('You don\'t have enough mBTs.')
          return;
        }
        setLoadingBuring(true)
        burnBbt(web3, tokenId).then(() => {
          getBBTDetails(web3, tokenId)
          setLoadingBuring(false)
        })
      },
      component: <GlobalModal clickToClose={!loadingBuring} modalHeader={modalMode} size='sm' mode='error' hideModal={hideModal}>
        <div>
          <div className='prompt'>
            <b>ATTENTIONS!</b>
            <div>By burning it, this art will be destroyed and no one on the planet can own it anymore.</div>
            <div className='prompt'>
              <b>you will recieve ≈ {burnRewards} BNB as rewards</b>
            </div>
          </div>
          <div className='prompt grey'>
            <div>* Burning costs <span className='code'>24000 mBT</span> + <span className='code'>1</span> BBT and it cannot be reverted</div>
          </div>
          <div className='prompt grey'>
            <div>* You need to know what you are doing with this burning feature.</div>
          </div>
          <Button customClass='danger' loading={loadingBuring} onClick={(e) => handleSubmit(e)}>I am sure to Burn</Button>
        </div>
      </GlobalModal>
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const regex = /[^A-Za-z0-9 ]/m
    // This is an amazing work that is amazing and minted by amazingandyyy
    const localValue = value.trim()
    MODES[modalMode].submitLogic(regex, localValue);
  }

  return (
    <div>
      {MODES[modalMode].component}
    </div>
  )
}

const mapStateToProps = ({ blockchain, wallet, home }) => {
  return {
    web3: blockchain.web3,
    mBTBalance: wallet.mBTBalance,
    burnRewards: home.burnRewards
  }
}

export default connect(mapStateToProps, { changeName, changeDescription, burnBbt, getBBTDetails })(Modal)
