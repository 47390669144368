import blockchain from './blockchain'
import {
  INIT_BLOCKCHAIN_ENVIRONMENT,
  CONNECT_WALLET,
  GET_WALLET_EVENTS,
  GET_USER_BALANCES,
  GET_CURRENT_BBT_DETAILS,
  GET_TRAITS,
  GET_DEX_DATA,
  GET_REF_EVENTS
} from '../types'
import connector, { GetUserBalancesMoreOnDemand } from '../../connector'
import axios from 'axios'
import { getRank } from '../../utils/functions'

export const connectWithMetaMask = (b) => async (dispatch) => {
  blockchain.connectWithMetaMask(b)
    .then((data) => {
      dispatch({
        type: CONNECT_WALLET,
        payload: data.blockchain
      })
    })
    .catch(err => console.log(err))
}

export const mintBBT = (amount, web3) => dispatch => {
  return connector
    .mintBBT(amount, web3)
    .then((data) => {
      dispatch(getDexData(web3))
    })
    .catch(err => console.log(err))
}

export const getDexData = (web3) => dispatch => {
  return connector
    .getDexData(web3)
    .then(data => {
      dispatch({
        type: GET_DEX_DATA,
        payload: { ...data }
      })
    })
}

export const getUserBalances = (web3, userAddress) => dispatch => {
  return connector
    .getUserBalances(web3, userAddress)
    .then((data) => {
      dispatch({
        type: GET_USER_BALANCES,
        payload: data
      })
    })
    .catch(err => console.log(err))
}

export const getUserBalancesMoreOnDemand = (web3, userAddress) => (dispatch, getState) => {
  const list = new GetUserBalancesMoreOnDemand(web3);
  list.on('newItem', (data)=>{
    dispatch({
      type: GET_USER_BALANCES,
      payload: {BBTCollection: data}
    })
  })

  list.subscribe(userAddress)
}

export const getUserBalancesMore = (web3, userAddress) => dispatch => {
  return connector
    .getUserBalancesMore(web3, userAddress)
    .then((data) => {
      dispatch({
        type: GET_USER_BALANCES,
        payload: data
      })
    })
    .catch(err => console.log(err))
}

export const initBlockchainEnvironment = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    blockchain
      .initBlockchainEnvironment()
      .then((data) => {
        resolve(data)
        dispatch({
          type: INIT_BLOCKCHAIN_ENVIRONMENT,
          payload: data
        })
        return data.web3
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const getPastWalletEvent = (web3, account='') => (dispatch, getState) => {
  return connector
    .getPastWalletEvent(web3, account)
    .then(data => {
      dispatch({
        type: GET_WALLET_EVENTS,
        payload: { events: data }
      })
    })
    .catch(err => console.log(err))
}

export const claimMbt = (web3, indexArr) => (dispatch, getState) => {
  return connector
    .claimMbt(web3, indexArr)
    .then(() => {
      if(indexArr.length === 1) {
        // means only claim for on BBT
        // and it's on the BBT details page
        const account = getState().blockchain.account
        dispatch(getBBTDetails(web3, indexArr[0]))
        dispatch(getUserBalances(web3, account))
      }
    })
    .catch(err => console.log(err))
}

export const changeName = (web3, tokenId, value) => (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .changeName(web3, tokenId, value)
    .then(() => {
      dispatch(getBBTDetails(web3, tokenId))
      dispatch(getUserBalances(web3, account))
      return 'success'
    })
    .catch(err => {
      console.log(err)
      return err
    })
}

export const changeDescription = (web3, tokenId, value) => (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .changeDescription(web3, tokenId, value)
    .then(() => {
      dispatch(getBBTDetails(web3, tokenId))
      dispatch(getUserBalances(web3, account))
      return 'success'
    })
    .catch(err => console.log(err))
}

export const getBBTDetails = (web3, tokenId) => dispatch => {
  return connector
    .getBBTDetails(web3, tokenId)
    .then((data) => {
      dispatch({
        type: GET_CURRENT_BBT_DETAILS,
        payload: data
      })
    })
}

export const getChangeLog = (web3, tokenId) => dispatch => {
  return connector
    .getChangeLog(web3, tokenId)
    .then((data) => {
      console.log('change log', data)
    })
    .catch(err => console.log(err))
}

export const burnBbt = (web3, tokenId) => dispatch => {
  return connector
    .burnBbt(web3, tokenId)
    .then(() => {})
    .catch(err => console.log(err))
}

export const connectToBSC = (web3, isMobile) => dispatch => {
  console.log('click connect tot BSC')
  return blockchain
    .connectToBSC(web3, isMobile)
    .then(() => {})
    .catch(err => console.log(err))
}
// NOTE: wallet page

// TODO: transfer from -- transfer BBT to other people -> need approve => two steps - OK

// Claim mBTToken - OK

// Claim all mBTToken - OK

// burnt BBT => spend mBT(need apporve) and claim BNB rewards -OK

// getAllWalletEvents - mintBBT, transfer to, transfer from, claim mBT, burn
// Mint,

// NOTE: BBT detail page

// get BBT info - name description - OK

// get change log - OK

// get accumulated mbttoken -OK

// get ownership -OK

// change name - OK

// change description - OK
// getTraits - gallery
export const getTraits = () => dispatch => {
  return window.fetch('https://thebearnbear.com/traits/all.json')
    .then(res => res.json())
    .then(data => dispatch({ type: GET_TRAITS, payload: { traits: data } }))
    .catch(err => console.log(err))
}

export const getRefEvents = () => (dispatch, getState) => {
  // const web3 = getState().blockchain.web3
  // return axios.get(`${process.env.REACT_APP_API_URL}/events`)
  //   .then(async (res) => {
  //     const newData = await getRank(web3, res.data)
  //     dispatch({ type: GET_REF_EVENTS, payload: { ranks: newData } })
  //   })
  //   .catch((err) => console.log(err))
}
