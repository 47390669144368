import {GET_USER_BALANCES, GET_WALLET_EVENTS} from '../redux/types'

const INITIAL_STATE = {
  events: [],
  BBTCollection: [],
  BBTBalance: 0,
  BNBBalance: 0,
  mBTBalance: 0
}

const walletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_BALANCES:
      return { ...state, ...action.payload }
    case GET_WALLET_EVENTS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default walletReducer
