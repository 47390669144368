import React from 'react'
import Bbt from './BBT'
import Lazyload from "react-lazyload";
import { CircleLoader } from '../../Global/ImageWithLoader'
const NFTList = ({ data, rowLength = 16, loading }) => {
  const hasItems = data.length > 0;
  let rest = [];
  data = data.reduce((acc, curr)=>{
    if(acc.cache.length === rowLength) {
      acc.rows.push(acc.cache);
      acc.cache = []
      rest = [];
    }else{
      acc.cache.push(curr);
      rest.push(curr);
    }
    return acc;
  }, {
    cache: [],
    rows: []
  }).rows
  data = [...data, rest]
  if (loading) {
    return <CircleLoader />
  }
  if (hasItems) {
    return (
      <div className='bbt-collection-container'>
        <div className='BBT-container'>
          {data.map((row, i) => (
            <Lazyload key={`0${i}0`} debounce={100} height={200} offset={200} once={true}>
              {row.map((item)=><Bbt key={item.tokenId} index={Number(item.tokenId)} />)}
            </Lazyload>
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className='bbt-collection-container'>
        <div className='BBT-container'>
          No arts.
        </div>
      </div>
    )
  }
}

export default NFTList
