import Web3 from 'web3'
import globalConfig from '../../global-config'
import web3instance from "../../connector/web3";

const METAMASK = 'metamask-compatible'
const logger = {
  log: (msg) => console.log('[ethereum-auth]', msg),
  warn: (msg) => console.warn('[ethereum-auth]', msg),
  error: (msg) => console.error('[ethereum-auth]', msg)
}
const network = process.env.REACT_APP_DEFAULT_NETWORK
const initBlockchainEnvironment = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      logger.log('initBlcochainEnvironment')
      window.web3 = null
      const blockchain = {
        account: '',
        balance: 0,
        network: '',
        web3: null
      }
      // no window.ethereum so no metamsk
      if (!window.ethereum) {
        const INFURA_ENDPOINT = globalConfig.bsc[network].rpcEndpoint
        blockchain.web3 = blockchain.web3 || new Web3(
          new Web3.providers.HttpProvider(INFURA_ENDPOINT)
        )
      } else {
        const provider = window.ethereum
        const localWeb3 = new Web3(provider)
        const accounts = await localWeb3.eth.getAccounts()
        if (accounts.length > 0) {
          blockchain.account = accounts[0]
          const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
          blockchain.balance = localWeb3.utils.fromWei(ethBalanceInWei, 'ether')
          blockchain.web3 = localWeb3
          if (localStorage.getItem('currentBlockchainWallet') === METAMASK) {
            blockchain.web3 = localWeb3
            // Subscribe to accounts change
            provider.on('accountsChanged', () => refreshBlockchainEnvironment())
            // Subscribe to chainId change
            provider.on('chainChanged', () => refreshBlockchainEnvironment())
            // Subscribe to session connection/open
            provider.on('open', () => console.log('open'))
            // Subscribe to session disconnection/close
            provider.on('close', (code, reason) => {
              localStorage.removeItem('currentBlockchainWallet')
              refreshBlockchainEnvironment()
            })
          }
        } else {
          if (localStorage.getItem('currentBlockchainWallet') === METAMASK) {
            localStorage.removeItem('currentBlockchainWallet')
          }
        }
        const isProd = process.env.REACT_APP_APP_ENV === 'production'
        const chainId = await localWeb3.eth.getChainId()
        if (isProd && chainId !== 56) {
          const AddEthereumChainParameter = globalConfig.bsc[network].chainConfig;
          if(window.confirm('You are not on Binance Smart Chain! Switch to Binance Smart Chain!')){
            if (window.ethereum) {
              window.ethereum
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [AddEthereumChainParameter] // you must have access to the specified account
                })
                .then((result) => {
                  console.log('result', result)
                  if(!result) {
                    connectWithMetaMask()
                  }
                  // blockchain.refreshBlockchainEnvironment()
                })
                .catch((error) => {
                  if (error.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    console.log('Cannot connect')
                  } else {
                    console.error(error)
                  }
                })
              }
          }
        }
      }
      return resolve(blockchain)
    } catch (e) {
      reject(e)
    }
  })
}

const refreshBlockchainEnvironment = () => {
  setTimeout(() => {
    window.location.reload()
  }, 100)
}

const connectWithMetaMask = async () => {
  if (!window.ethereum) return window.alert('Metamask wallet is not installed!')
  // prompt user to login metamask
  try {
    const provider = window.ethereum
    await provider.request({
      method: 'eth_requestAccounts'
    })
    const web3 = new Web3(provider)
    const accounts = await web3.eth.getAccounts();
    const blockchain = {
      account: '',
      balance: 0,
      network: '',
      web3: null
    }
    const connected = accounts.length > 0
    if (connected) {
      logger.log('connected with METAMASK')
      localStorage.setItem('currentBlockchainWallet', METAMASK)
      let currentWalletAddress = localStorage.getItem('currentWalletAddress')
      if (!currentWalletAddress) {
        currentWalletAddress = accounts[0]
        localStorage.setItem('currentWalletAddress', accounts[0])
      } else if (accounts[0] !== currentWalletAddress) {
        currentWalletAddress = accounts[0]
        localStorage.setItem('currentWalletAddress', accounts[0])
      }
      blockchain.account = currentWalletAddress
      const balanceInWei = await web3.eth.getBalance(currentWalletAddress)
      const balance = await web3.utils.fromWei(balanceInWei, 'ether')
      blockchain.balance = balance
      blockchain.network = network
      blockchain.web3 = web3
      console.log('blockchain', blockchain)
      refreshBlockchainEnvironment()
      return { blockchain }
    }
  } catch (e) {
    logger.error(e)
  }
}

const connectToBSC = async (web3) => {
  web3 = web3instance(web3)
  const AddEthereumChainParameter = globalConfig.bsc[network].chainConfig;
  if (window.ethereum) {
    window.ethereum
      .request({
        method: 'wallet_addEthereumChain',
        params: [AddEthereumChainParameter] // you must have access to the specified account
      })
      .then((result) => {
        console.log('result', result)
        if(!result) {
          connectWithMetaMask()
        }
        // blockchain.refreshBlockchainEnvironment()
      })
      .catch((error) => {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          console.log('Cannot connect')
        } else {
          console.error(error)
        }
      })
  }
}

const disconnectWithMetaMask = () => {
  logger.log('disconnectWithMetaMask')
}

const blockchain = {
  initBlockchainEnvironment,
  connectWithMetaMask,
  connectToBSC,
  disconnectWithMetaMask,
  refreshBlockchainEnvironment
}

export default blockchain
