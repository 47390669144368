import React from "react"

const Referrals = ({ events }) => {
  console.log("events", events)
  const getTotal = (events) => {
    let totalBonus = 0
    for (let i = 0; i < events.length; i++) {
      const e = events[i]
      totalBonus += Number(e.BNBBonus)
    }
    return totalBonus
  }
  return (
    <div className='event-list-container'>
      <div className='event-list'>
        <div className='event-item title'>
          <div className='ranks'>#</div>
          <div className='refererAddress'>Referrals</div>
          <div className='BNBBonus'>Bonus Earned</div>
        </div>
        {events.length > 0 ? (
          <>
            {events.map((event, i) => {
              return (
                <div
                  key={`${i}-${event.transactionHash}`}
                  className='event-item'
                >
                  <div className='ranks'>{i+1}</div>
                  <div className='refererAddress'>{event.buyerAddress}</div>
                  <div className='BNBBonus'>{Number(event.BNBBonus).toFixed(3)} BNB</div>
                </div>
              )
            })}
            <div
              className='event-item'
            >
              <div className='ranks'>Total</div>
              <div className='refererAddress'>{events.length} referrals </div>
              <div className='BNBBonus'>{getTotal(events)} BNB</div>
            </div>
          </>
        ) : (
          <div className='event-item no-event'>no referrals</div>
        )}
      </div>
    </div>
  )
}

export default Referrals
