import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './components/Home'
import Wallet from './components/Wallet'
import Gallery from './components/Gallery'
import MBTToken from './components/MBTToken'
import Overview from './components/Overview'
import BbtDetail from './components/BbtDetail'
import Leaderboard from './components/Leaderboard'
class Router extends React.Component {
  render () {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/crowdsale' component={Home} />
        <Route exact path='/overview' component={Overview} />
        <Route exact path='/collection' component={Wallet} />
        <Route exact path='/collection/:address' component={Wallet} />
        <Route exact path='/bbt/:tokenId' component={BbtDetail} />
        <Route exact path='/gallery' component={Gallery} />
        <Route exact path='/mBT' component={MBTToken} />
        {/*<Route exact path='/internal-leaderboard' component={Leaderboard} />*/}
      </Switch>
    )
  }
}

export default Router
