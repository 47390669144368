import React, {useState} from 'react'
import { connect } from 'react-redux'
import Hero from './hero'
import ProgressBar from './progressBar'
import Stars from '../stars'
import content from '../content.json'
import CountDownComponent from './countDown'
import Stats from './stats';
import Button from "../Global/Button";
import BuyModal from "../Wallet/BuyModal";
import Image from "../Global/ImageWithLoader";

const Index = ({ account, currentBlockTimeStamp, canBuy, accumulatedSupply, contractBalance, burnRewardPoolPercentage, finalRewardPoolSize, startingIndex }) => {
  const [showBuyModal, setShowBuyModal] = useState(false)
  return (
    <div className='landing-page'>
      <Stars />
      <Hero />
      <div className='announcement-container'>
        {/*{account.length === 0 && <a href='https://thebearnbear.medium.com/' target='_blank' rel='noopener noreferrer' className='announcement'>*/}
        {/*  <span>new</span>*/}
        {/*  <div>Refer your friends and get 25% bonus in BNB 🎉</div>*/}
        {/*</a>}*/}
        {/*{account.length > 0 && <a href={`/#/collection/${account}`} className='announcement'>*/}
        {/*  <span>new</span>*/}
        {/*  <div>Refer your friends and get 25% bonus in BNB 🎉</div>*/}
        {/*</a>}*/}
        <a href={`/#/gallery`} className='announcement'>
          <span>NOW</span>
          <div>All 4354 Bears are successfully minted and revealed! 🎉</div>
        </a>
      </div>
      {/*{canBuy && <Button onClick={() => setShowBuyModal(true)}>Get your BearNBear</Button>}*/}
      {(accumulatedSupply===17153) && <Button disabled={true}>Sold Out!</Button>}
      {!canBuy && (accumulatedSupply<17153) && <CountDownComponent currentBlockTimeStamp={currentBlockTimeStamp}/>}
      {canBuy && <Stats accumulatedSupply={accumulatedSupply} contractBalance={contractBalance}
              burnRewardPoolPercentage={burnRewardPoolPercentage} finalRewardPoolSize={finalRewardPoolSize}
              startingIndex={startingIndex}/>}
      <div className='page-container timeline-container'>
        {/*<ProgressBar />*/}
        {/*<div>*/}
        {/*  <h2>The Initial Crowdsale</h2>*/}
        {/*  <p>{content.home.TimelinePrompt[0]}</p>*/}
        {/*  <p>{content.home.TimelinePrompt[1]}</p>*/}
        {/*  <div className='unrevealed-envolope'>*/}
        {/*    <img src={require('../../assets/images/unrevealed-envolope.png').default} alt='unrevealed-envolope' />*/}
        {/*  </div>*/}
        {/*  <p>{content.home.TimelinePrompt[2]}</p>*/}
        {/*</div>*/}
        <div className='hero-bears'>
          {/*<h2>What is BearNBearToken (BBT)</h2>*/}
          <img src={require('../../assets/images/hero-bears.png').default} alt='hero-bears' />
          <div>
            <p>{content.introduction.description1}</p>
            <p>{content.introduction.description2}</p>
            <Image className='traits-bear' src={require('../../assets/images/traits-bear-2.png').default} alt='traits-bear-2' />
            <p>{content.introduction.description3}</p>
          </div>
        </div>
        <div>
          <img width={250} src={require('../../assets/images/bbt-bnb.png').default} alt='unrevealed-envolope' />
          <h2>The first of its kind: deflationary NFT</h2>
          <div className='stat-info'>
            <div className='info-group'>
              <div className='item'>
                Community Shares Up to
                <div className='number'>
                  {(burnRewardPoolPercentage * 100).toFixed(0)}%
                </div>
                <div className='units percentage'>of total raised BNB</div>
              </div>
              <div className='item'>
                Up to
                <div className='number'>80%</div>
                <div className='units'>BearNBear burned</div>
              </div>
              <div className='item'>
                Token value backs by
                <div className='number'>BNB</div>
                <div className='units'>Binance Coins</div></div>
            </div>
          </div>
          <div>{content.home.KillerFeatures.map(r => <h3 key={r}>{r}</h3>)}</div>
        </div>
        <div className='contracts'>
          <h2>Contracts & Records</h2>
          <div>BearNBearToken Contract:
            <div className='wrap'>
              <a target='_blank' rel='noopener noreferrer' href='https://bscscan.com/address/0xbD8724Ec4F1Bbf98ce0db8612dE67E0a192F798d'>0xbD8724Ec4F1Bbf98ce0db8612dE67E0a192F798d</a>
            </div>
          </div>
          <div>MiniBeartoken Contract:
            <div className='wrap'>
              <a target='_blank' rel='noopener noreferrer' href='https://bscscan.com/address/0xA5eb55A316aA589dc09ea63C7d53F6Ba44e23a21'>0xA5eb55A316aA589dc09ea63C7d53F6Ba44e23a21</a>
            </div>
          </div>
          <div>
            Provenance Record:
            <div className='wrap'>
              <a target='_blank' rel='noopener noreferrer' href='https://provenance.thebearnbear.com'> ae0eaabd9134aa09782b4dfd43a7280adeea9987f1e4cdf4817f610716ec5685</a>
            </div>
          </div>
        </div>
      </div>
      {showBuyModal && <BuyModal hideModal={() => setShowBuyModal(false)} modalHeader='Buy BearNBear' />}
    </div>
  )
}

const mapStateToProps = ({ blockchain, home }) => {
  return {
    currentBlockTimeStamp: home.currentBlockTimeStamp,
    canBuy: home.currentBlockTimeStamp > home.startingTimeStamp && (home.accumulatedSupply<17153),
    isSoldOut: home.hideModal,
    accumulatedSupply: home.accumulatedSupply,
    contractBalance: home.contractBalance,
    burnRewardPoolPercentage: home.burnRewardPoolPercentage,
    finalRewardPoolSize: home.finalRewardPoolSize,
    web3: blockchain.web3, supply: home.accumulatedSupply,
    startingIndex: home.startingIndex,
    burnRewards: home.burnRewards,
    account: blockchain.account
  }
}
export default connect(mapStateToProps, {})(Index)
