import React from "react";
import { connect } from 'react-redux'

import globalconfig from '../../global-config'
const burnRewardPoolRecords = [700000000000000000, 690184328064006930, 680506295293092491, 670963971657413890, 661555454190756566, 652278866611037483, 643132358946129872, 634114107164934803, 625222312813626013, 616455202656995457, 607811028324828041, 599288065963235036, 590884615890876622, 582599002260005018, 574429572722260597, 566374698099154335, 558432772057170882, 550602210787427472, 542881452689824768, 535268958061626679, 527763208790407021, 520362708051301808, 513065980008506782, 505871569520960666, 498778041852155436, 491783982384015752, 484887996334790475, 478088708480900027, 471384762882684109, 464774822613995094, 458257569495583167, 451831703832220039, 445495944153508818, 439249026958328343, 433089706462861019, 427016754352153903, 421028959535163498, 415125127903235403, 409304082091970658, 403564661246431286, 397905720789638221, 392326132194315441, 386824782757834799, 381400575380316663, 376052428345842115, 370779275106733076, 365580064070857342, 360453758391916109, 355399335762672170, 350415788211077542, 345502121899259878, 340657356925327561, 335880527127953972, 331170679893701948, 326526875967050013, 321948189263082495, 317433706682806175, 312982527931056637, 308593765336958009, 304266543676900281, 300000000000000000];
const fromWei = 1000000000000000000;
const Stats = ({ burnCount, burnRewards, accumulatedSupply, contractBalance, burnRewardPoolPercentage, finalRewardPoolSize, startingIndex}) => {
  const secondPerDay = 60*60*24;
  const days = Math.floor((Math.floor(Date.now()/1000) - globalconfig.bsc.main.contractInfo.startingTimeStamp)/secondPerDay)
  const communitySharesPercentage = () => {
    return burnRewardPoolRecords[days]/fromWei*100;
  }
  return (
    <div className='stat-info'>
    {/*<div className='info-group'>*/}
    {/*  <div className='item'>*/}
    {/*    Total Minted*/}
    {/*    <div className='number'>{accumulatedSupply}</div>*/}
    {/*    <div className='units'>BearNBearToken</div>*/}
    {/*  </div>*/}
    {/*  <div className='item'>*/}
    {/*    Community Shares*/}
    {/*    <div className='number'>*/}
    {/*      /!* {(burnRewardPoolPercentage * 100).toFixed(0)}% *!/*/}
    {/*      {communitySharesPercentage().toFixed(0)}%*/}
    {/*    </div>*/}
    {/*    <div className='units percentage'>of total BNB</div>*/}
    {/*  </div>*/}
    {/*  <div className='item'>*/}
    {/*    DAO Reward Pool*/}
    {/*    /!* <div className='number'>*/}
    {/*      {startingIndex === 0 ? (Number(contractBalance)*Number(burnRewardPoolPercentage)).toFixed(2) : finalRewardPoolSize.toFixed(2)}*/}
    {/*    </div> *!/*/}
    {/*    <div className='number'>*/}
    {/*      {Number(Number(contractBalance+burnCount*burnRewards)*communitySharesPercentage()/100 - Number(burnCount*burnRewards)).toFixed(2)}*/}
    {/*    </div>*/}
    {/*    <div className='units'>Binance Coins (BNB)</div></div>*/}
    {/*</div>*/}
    </div>
  )
}

const mapStateToProps = ({ blockchain, home }) => {
  return { web3: blockchain.web3, burnCount: home.burnCount, burnRewards:home.burnRewards }
}

export default connect(mapStateToProps, null)(Stats)

