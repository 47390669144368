import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import {
  claimMbt,
  getBBTDetails,
  getChangeLog,
  getUserBalances
} from "../../redux/actions"
import Modal from "./Modal"
import Image from '../Global/ImageWithLoader'
import { getImageSrc } from "../../utils/functions";

// unrevealed startIndex == 0
// revealed
// burned

const Index = ({
  match,
  web3,
  name,
  description,
  ownerAddress,
  accumulated,
  claimMbt,
  getBBTDetails,
  account,
  startingIndex,
  wallet,
  getUserBalances,
  isRevealed,
  isBurned
}) => {
  const [tokenId, setTokenId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalMode, setModalMode] = useState('')
  const [attributes, setAttribute] = useState([])
  const [link, setLink] = useState('')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getBBTDetails(web3, match.params.tokenId)
    setTokenId(match.params.tokenId)
    getUserBalances(web3, account)
    // getChangeLog(web3, match.params.tokenId)
    window.fetch(`https://thebearnbear.com/traits/${match.params.tokenId}.json`)
      .then(res => res.json())
      .then(data => {
        setAttribute(data.attributes)
        setLink(data.image)
      })
      .catch(err => console.log(err))
  }, [match.params, web3, getUserBalances, account, getBBTDetails, startingIndex])

  const handleChangeClick = (mode) => {
    setShowModal(true)
    setModalMode(mode)
  }

  const hideModal = () => {
    setShowModal(false)
  }

  const renderArtwork = () => {
    if(isBurned) return <div style={{marginTop: '100px' }}>Art not found</div>
    if(!isRevealed) {
      return <div className='bearnbear-artwork-container'>
        <div className='index code'>BearNBear #{tokenId}</div>
        <div>
          <Image className='bearnbear-artwork' src={require('../../assets/images/unrevealed-envolope.png').default} alt='bearNbear-unrevealed' />
        </div>
        <div>
          <h2>not revealed yet</h2>
        </div>
        <div className='ownerAddress'>owned by <a target='_blank' rel='noopener noreferrer' href={`/#/collection/${ownerAddress}`}><span className='code'>{ownerAddress}</span></a></div>
        <div className='accumulated'><span className='code'>0 mBT accumulated</span></div>
      </div>
    }

    const imageSrc = getImageSrc(match.params.tokenId, startingIndex)
    return <div className='bearnbear-artwork-container'>
      <div className='index code'>BearNBear #{tokenId}</div>
      <div>
        <Image className='bearnbear-artwork' src={imageSrc.large} alt={`bearNbear-${match.params.tokenId}`} />
      </div>
      <a target='_blank' rel='noopener noreferrer' href={link}>Download Original</a>
      <div>
        <h2>{name}</h2>
      </div>
      <pre className='description'>{description}</pre>
      <div className='ownerAddress'>owned by <a target='_blank' rel='noopener noreferrer' href={`/#/collection/${ownerAddress}`}><span className='code'>{ownerAddress}</span></a></div>
      <div className='accumulated'><span className='code'>{accumulated} mBT accumulated</span></div>
      <div className='traits-group'>
        {attributes.map(a => renderTrait(a, attributes))}
      </div>
    </div>
  }

  const handleItemClick = (traitType, value) => {
    const params = new URLSearchParams({ [traitType]: value });
    window.open(`${window.location.origin}/#/gallery?${params.toString()}`)
  }

  const renderTrait = (a, attributes) => {
    if (!a.trait_type.includes('_p') && a.trait_type !== 'scarcity') {
      return (
        <div className='item' onClick={() => handleItemClick(a.trait_type, a.value)}>
          <div className='title'>{a.trait_type.slice(0, 1).toUpperCase()}{a.trait_type.slice(1)}</div>
          <div>{a.value}</div>
          {/* {renderP(attributes, a.trait_type)} */}
        </div>
      )
    }
  }

  // const renderP = (attributes, traitType) => {
  //   const find = attributes.find(o => o.trait_type === traitType + '_p')
  //   if (find) {
  //     return <div>{find.value}</div>
  //   }
  // }

  const renderOwnerManagement = () => {
    const isOwner = ownerAddress === account;
    if(isOwner) {
      return <div>
        <div className='owner-actions'>
          <div className='owner-info'>
            <div>owner management</div>
            <small>only owner can see this session</small>
          </div>
          <button className='green' onClick={() => claimMbt(web3, [tokenId])} disabled={!(Number(accumulated) > 0)}>Claim all mBT (≈ {Number(accumulated).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} mBT)</button>
          <button disabled={(Number(wallet.mBTBalance)<2000)} onClick={() => handleChangeClick("Change Name")}>
            Change Name ({Number(2000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} mBT)
          </button>
          <button disabled={(Number(wallet.mBTBalance)<2000)} onClick={() => handleChangeClick("Change Description")}>
            Change Description ({Number(2000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} mBT)
          </button>
          {Number(startingIndex) !== 0 &&
          <button className='red' disabled={(Number(wallet.mBTBalance)<24000)} onClick={() => handleChangeClick("Burn")}>
            Burn ({Number(24000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} mBT)
          </button>}
        </div>
        {showModal && (
          <Modal
            modalMode={modalMode}
            hideModal={hideModal}
            tokenId={tokenId}
          />
        )}
      </div>
    }else{
      return <></>
    }
}

  return (
    <div className='page-container bbt-details-container'>
      {renderArtwork()}
      {Number(startingIndex) !== 0 && renderOwnerManagement()}
    </div>
  )
}

const mapStateToProps = ({ BBT, blockchain, home, wallet }) => {
  return {
    web3: blockchain.web3,
    account: blockchain.account,
    startingIndex: home.startingIndex,
    wallet,
    isRevealed: home.startingIndex !== 0,
    ...BBT,
    isBurned: BBT && BBT.ownerAddress === 0
  }
}

export default connect(mapStateToProps, {
  claimMbt,
  getBBTDetails,
  getChangeLog,
  getUserBalances
})(Index)
