import React from 'react'
import { Img } from 'react-image'
// import { LazyLoadImage } from 'react-lazy-load-image-component';
//
// <LazyLoadImage
//   alt={`bearNbear-${imageSrc.assetId}`}
//   src={imageSrc.small} />

export const CircleLoader = (props) => {
  return (
    <div className='loader-component' {...props}>
      <div className='circle-spinner'>
        <div className='double-bounce1' style={{ ...props.loaderStyle }} />
        <div className='double-bounce2' style={{ ...props.loaderStyle }} />
      </div>
    </div>
  )
}

const Image = (props) => {
  return (
    <Img
      {...props}
      src={props.src}
      loader={<CircleLoader />}
    />
  )
}

export default Image
