import React, {useEffect, useState} from 'react'
import metamaskLogo from '../../assets/images/metamask-logo.png'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { connectWithMetaMask, getUserBalances, claimMbt, connectToBSC, getPastWalletEvent, getUserBalancesMore, getUserBalancesMoreOnDemand } from '../../redux/actions'
import Events from './Events'
import Referrals from './Referrals'
import BlockiesComponent from '../Blockies';
import Button from '../Global/Button';
import BuyModal from "./BuyModal";
import { toast } from 'react-toastify'
import axios from 'axios'
import NFTList from "../Global/NFTList";
import { getRank, getReferrals } from '../../utils/functions'
import {
  isMobile
} from "react-device-detect"
import bigNumber from 'bignumber.js'
const Index = ({ getUserBalancesMoreOnDemand, getUserBalancesMore, canBuy, BBTCollection, match, account, wallet, web3, getUserBalances, getPastWalletEvent, BBTCollectionCount, connectToBSC, claimMbt, startingIndex, eventCount }) => {
  const [showBuyModal, setShowBuyModal] = useState(false)
  const [currentAccount, setCurrentAccount] = useState('')
  const [isOwner, setIsOwner] = useState(false)
  const [tab, setTab] = useState('0')
  const [loading, setLoading] = useState(true)
  const [BNBBonus, setBNBBonus] = useState(0)
  const [refEvents, setRefEvents] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    setCurrentAccount(match.params.address || account)
    if(!match.params.address && Boolean(account)){
      setIsOwner(true)
    }else{
      setIsOwner(match.params.address === account)
    }
  }, [account, match])

  useEffect(() => {
    getPastWalletEvent(web3, account)
  }, [getPastWalletEvent, web3, account])

  useEffect(()=>{
    setLoading(true)
    getUserBalances(web3, currentAccount)
      .then(() => {
        setLoading(false)
      })
  }, [getUserBalances, web3, currentAccount])

  const handleClaimAll = () => {
    const cacheBBTCollection = wallet.BBTCollection.slice(0)
    if(cacheBBTCollection.length === Number(BBTCollectionCount)) {
      if(cacheBBTCollection.length > 500) {
        claimMbt(web3, [...new Set(cacheBBTCollection.slice(0, 500).map(b => Number(b.tokenId)).sort((a,b)=>a-b))])
          .then(() => getUserBalances(web3, account))
        claimMbt(web3, [...new Set(cacheBBTCollection.slice(500).map(b => Number(b.tokenId)).sort((a,b)=>a-b))])
          .then(() => getUserBalances(web3, account))
      }else{
        toast.error('You request to claim all mBT. This is an expensive function, know what it costs before executing it.')
        const yes = window.confirm('Are you sure you want to claim all mBT?')
        if(yes){
          const indexArr = wallet.BBTCollection.map(b => Number(b.tokenId))
          const indexesToUse = [...new Set(indexArr.sort((a,b)=>a-b))];
          claimMbt(web3, indexesToUse)
            .then(() => {
              getUserBalances(web3, account)
            })
        }
      }
    }else{
      window.alert(`You haven't load all your bears. Please click "load more" to load all your ${BBTCollectionCount} bears before calling claimAll function!`)
    }
  }

  const handleConnectToBSC = () => {
    connectToBSC(web3)
  }

  const renderConnect = () => {
    if(isMobile && window.ethereum){
      return <><Button
        customClass={`secondary ${Boolean(window.ethereum)}`}>
        <div className='active'>✓</div>
        Metamask Mobile</Button>
        <Button onClick={() => connectWithMetaMask()}>Connect with MetaMask</Button></>
    } else if (window.ethereum) {
      return <><Button
        customClass={`secondary ${Boolean(window.ethereum)}`}>
        <div className='active'>✓</div>
        Metamask Installed</Button>
        <Button onClick={() => handleConnectToBSC()}>Connect with MetaMask</Button></>
    } else if(isMobile){
      return <a href='https://metamask.app.link/dapp/thebearnbear.com/#/collection/'><Button>Connect with MetaMask</Button></a>
    } else{
      return <a href='https://metamask.io/download.html' target='_blank' rel='noopener noreferrer'><Button
        customClass={`${Boolean(window.ethereum)}`}>
        Install Metamask</Button>
      </a>
    }
  }

  const handleRefLink = () => {
    const textField = document.createElement('textarea')
    textField.innerText = `${window.location.origin}?r=${account}`
    // textField.innerText = `https://thebearnbear.com?address=${account}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    toast.success('🎉 Referral Link Copied!')
    textField.remove()
  }
  const handleTweet = () => {
    const link = `${window.location.origin}?r=${account}`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `Check out @TheBearNBear and join the continue growing #deflationaryNFT community today!

👉 ${link}

@BinanceChain #NFT
      `
    )}`;
    window.open(url)
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/event/${account}`)
      .then(async (res) => {
        const events = await getReferrals(web3, res.data)
        setRefEvents(events)
        return getRank(web3, res.data)
      })
      .then(res => {
        setBNBBonus(res[0].BNBBonus)
      })
      .catch(err => console.log(err))
  }, [account, web3])
  return (
    <div className='page-container wallet-container'>
      {(currentAccount.length > 0) ? (
        <>
          {/*{isOwner && <div className='referral-container'>*/}
          {/*  <div id='referral-program' className='title'>*/}
          {/*    🔉 Referral program <a href='https://thebearnbear.medium.com/bearnbear-launches-referral-program-1f06d5127e51' target='_blank' rel='noopener noreferrer' className='leaderboard-note wallet'>See Details</a>*/}
          {/*  </div>*/}
          {/*  <div className='subtitle'>*/}
          {/*    With your unique referral link, you get <b>25%</b> commissions on every BearNBear your friend bought!*/}
          {/*  </div>*/}
          {/*  <div className='referral-inner-container'>*/}
          {/*    <div className='code'>{`${window.location.origin}?r=${account}`}</div>*/}
          {/*    <div className='ref-link primary' onClick={() => handleRefLink()}>Copy Referral Link</div>*/}
          {/*    <div className='ref-link twitter' onClick={() => handleTweet()}>*/}
          {/*      <img alt='logo' src={require('./twitter-icon.svg').default}/>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className='subtitle'>*/}
          {/*    You have earned {Number(BNBBonus).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })} BNB referral bonus so far, check*/}
          {/*    <Link to='/leaderboard'><b>{` LEADERBOARD.`}</b></Link>*/}
          {/*    <br />*/}
          {/*  </div>*/}
          {/*</div>}*/}
          {isOwner && <h2 className='my-collection'>My Collection</h2>}
          <div className='profile-container'>
            <div className='profile-image'>
              <BlockiesComponent address={currentAccount} />
            </div>
            <div className='information'>
              <div className='code'>{currentAccount}</div>
              {isOwner && <div className='balance-action-container'>
                <div className='item bbt'>{wallet.BBTBalance} BearNBearToken (BBT)</div>
                <div className='item mbt'>{wallet.mBTBalance} miniBearToken (mBT)</div>
              </div>}
              {isOwner && <div className='balance-action-container'>
                {/*{canBuy && <Button onClick={() => setShowBuyModal(true)}>Buy more</Button>}*/}
                {Number(startingIndex) !== 0 && <Button onClick={() => handleClaimAll()}>Claim all mBT</Button>}
              </div>}
            </div>
          </div>
          <div className='tab-group'>
            <Button customClass='tab' mode={tab === '0' ? 'primary' : 'secondary'} onClick={() => setTab('0')}>{BBTCollection.length}/{BBTCollectionCount} BearNBear</Button>
            {/* {isOwner && <Button customClass='tab' mode={tab === '1' ? 'primary' : 'secondary'} onClick={() => setTab('1')}>Events</Button>} */}
            {/*{isOwner && <Button customClass='tab' mode={tab === '2' ? 'primary' : 'secondary'} onClick={() => setTab('2')}>Referrals</Button>}*/}
          </div>
          {/*{!tab && <UserNFT />}*/}
          {tab === '0' && <NFTList data={BBTCollection} loading={loading}/>}
          <div style={{marginTop: '30px'}}>
            {tab === '0' && (BBTCollectionCount>12) && (BBTCollectionCount-BBTCollection.length !== 0) && <Button onClick={()=>getUserBalancesMoreOnDemand(web3, currentAccount)}>Load {BBTCollectionCount-BBTCollection.length} more</Button>}
          </div>
          {isOwner && tab === '1' && <Events account={currentAccount}/>}
          {isOwner && tab === '2' && refEvents && <Referrals events={refEvents}/>}
          {showBuyModal && <BuyModal hideModal={() => setShowBuyModal(false)} />}
        </>
      ) : (
        <>
          <img className='logo' alt='metamask' src={metamaskLogo} />
          <div className='tutorial'>
            {renderConnect()}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ blockchain, wallet, home }) => {
  return {
    ...blockchain,
    wallet,
    startingIndex: home.startingIndex,
    BBTCollection: wallet.BBTCollection,
    BBTCollectionCount: wallet.BBTBalance,
    eventCount: wallet.events.length,
    canBuy: home.currentBlockTimeStamp > home.startingTimeStamp && (home.accumulatedSupply<17153)
  }
}

export default connect(mapStateToProps, { connectWithMetaMask, getUserBalancesMoreOnDemand, getPastWalletEvent, getUserBalances, getUserBalancesMore, claimMbt, connectToBSC })(Index)
