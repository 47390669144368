import React from 'react'
import {connect} from "react-redux";

const Navbar = ({account}) => {
  return (
    <>
      <a target='_blank' rel='noopener noreferrer' href='https://lootex.io/stores/bearnbear?ref=bearnbearofficial' className='announcement'>
        <div className='announcement-banner-top'>
          <span className='no-show-mobile new'>partners</span>
          {/*<div className='no-show-mobile'>Partners</div>*/}
          <div>Trade BearNBear on lootex marketplace</div>
          {/*<div>Refer and get <b>25%</b> commissions in BNB now.</div>*/}
          {/*<span className='pointer no-show-mobile'>{` Join now`}</span>*/}
        </div>
      </a>
    </>
  )
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account
  }
}

export default connect(mapStateToProps, null)(Navbar)
