import BigNumber from "bignumber.js"
import web3instance from '../connector/web3'
import bearNBear from '../connector/contracts/BearNBearToken.json'
import InputDataDecoder from 'ethereum-input-data-decoder'
import axios from 'axios'
import bigNumber from 'bignumber.js'
const decoder = new InputDataDecoder(bearNBear.abi)
const startingBlockNumber = 6576963
const priceModel = [
  {
    tier: 1,
    min: 1,
    max: 3600,
    price: 0.1
  },
  {
    tier: 2,
    min: 3601,
    max: 7100,
    price: 0.3
  },
  {
    tier: 3,
    min: 7101,
    max: 10500,
    price: 0.6
  },
  {
    tier: 4,
    min: 10501,
    max: 13800,
    price: 1.2
  },
  {
    tier: 5,
    min: 13801,
    max: 17050,
    price: 2.4
  },
  {
    tier: 6,
    min: 17051,
    max: 17150,
    price: 10
  },
  {
    tier: 7,
    min: 17151,
    max: 17153,
    price: 100
  }
]

export const calculateTotalPrice = (amount, totalSupply) => {
  amount = Number(amount)
  totalSupply = Number(totalSupply)// so it will be the token index
  const currentTierIndex = getCurrentTierIndex(totalSupply)
  if (Number(totalSupply) + Number(amount) < priceModel[currentTierIndex].max || Number(totalSupply) + Number(amount) === priceModel[currentTierIndex].max) {
    // withinTier
    const price = priceModel[currentTierIndex].price
    return Number(BigNumber(amount).multipliedBy(price).toString())
  } else {
    // crosstier
    const price1 = priceModel[currentTierIndex].price
    const price2 = priceModel[currentTierIndex + 1].price
    const amount1 = priceModel[currentTierIndex].max - Number(totalSupply)
    const amount2 = Number(amount) - amount1
    return Number(amount1) * price1 + Number(amount2) * price2
  }
}

export const getCurrentTierIndex = (totalSupply) => {
  let currentTierIndex = 0
  for (let i = 0; i < priceModel.length; i++) {
    const tier = priceModel[i]
    if ((totalSupply > tier.min || totalSupply === tier.min) && (totalSupply < tier.max || totalSupply === tier.max)) {
      currentTierIndex = i
    }
  }
  return currentTierIndex
}
const specialTokenIds = [17150, 17151, 17152]
const gifsAssetIds = [939, 1920, 2901, 4183, 5137, 6632, 7950, 9015, 9957, 10991, 12319, 13591, 14794, 15780, 16812, 17151, 17152, 17153];
const imageProxy = 'https://storage.googleapis.com/bearnbear1'
const offset = 1;

export const getImageSrc = (tokenId, startingIndex) => {
  tokenId = Number(tokenId)
  startingIndex = Number(startingIndex)
  const isSpecialTokenId = specialTokenIds.includes(tokenId)

  const assetId = (isSpecialTokenId) ? (tokenId + offset) : ((tokenId + startingIndex) % 17150 + offset)
  const isGif = gifsAssetIds.includes(assetId);
  const fileType = (isGif) ? 'gif' : 'png';

  return {
    assetId,
    small: `${imageProxy}/bbt-preview/${assetId}.${fileType}`,
    large: `${imageProxy}/bbt/${assetId}.${fileType}`,
  }
}

export const getRank = async (web3, data) => {
  web3 = web3instance(web3)
  const refererAddresses = {}
  for (let i = 0; i < data.length; i++) {
    const test = data[i]
    if (test.refererAddress === '0') {
      continue
    }

    if (!refererAddresses[test.refererAddress]) {
      refererAddresses[test.refererAddress] = []
    }
    refererAddresses[test.refererAddress].push(test.txnHash)
  }
  const r = await axios.get('https://api.coingecko.com/api/v3/coins/binancecoin')
  const v = r.data.market_data.current_price.usd
  console.log('v', v)
  const arr = []
  for (const i in refererAddresses) {
    const hashArr = refererAddresses[i]
    let BBTAmount = 0
    let BNBAmount = 0
    for (let i = 0; i < hashArr.length; i++) {
      const hash = hashArr[i]
      await web3.eth.getTransaction(hash)
        .then(async (res) => {
          const blockNumber = res.blockNumber
          if (blockNumber > startingBlockNumber) {
            const BNBAmountToAdd = Number(await web3.utils.fromWei(res.value, 'ether'))
            BNBAmount += BNBAmountToAdd
            const BBTAmountBN = decoder.decodeData(res.input)
            BBTAmount += Number(BBTAmountBN.inputs.toString())
          }
        })
        .catch(err => console.log('[err on getTransaction', err))
    }
  
    arr.push({
      refererAddress: i,
      BNBAmount,
      BBTAmount,
      BNBBonus: bigNumber(BBTAmount).times(0.3).times(0.25).toString(),
      BNBBonusInUSD: bigNumber(BBTAmount).times(0.3).times(0.25).times(v).toString()
    })
  }
  arr.sort((a, b) => b.BBTAmount - a.BBTAmount)
  return arr
}

export const getReferrals = async (web3, data) => {
  web3 = web3instance(web3)
  const buyerAddresses = {}
  for (let i = 0; i < data.length; i++) {
    const test = data[i]

    if (!buyerAddresses[test.buyerAddress]) {
      buyerAddresses[test.buyerAddress] = []
    }
    buyerAddresses[test.buyerAddress].push(test.txnHash)
  }
  const r = await axios.get('https://api.coingecko.com/api/v3/coins/binancecoin')
  const v = r.data.market_data.current_price.usd
  console.log('v', v)
  const arr = []
  for (const i in buyerAddresses) {
    const hashArr = buyerAddresses[i]
    let BBTAmount = 0
    let BNBAmount = 0
    for (let i = 0; i < hashArr.length; i++) {
      const hash = hashArr[i]
      await web3.eth.getTransaction(hash)
        .then(async (res) => {
          const blockNumber = res.blockNumber
          if (blockNumber > startingBlockNumber) {
            const BNBAmountToAdd = Number(await web3.utils.fromWei(res.value, 'ether'))
            BNBAmount += BNBAmountToAdd
            const BBTAmountBN = decoder.decodeData(res.input)
            BBTAmount += Number(BBTAmountBN.inputs.toString())
          }
        })
        .catch(err => console.log('[err on getTransaction', err))
    }
  
    arr.push({
      buyerAddress: i,
      BNBAmount,
      BBTAmount,
      BNBBonus: bigNumber(BBTAmount).times(0.3).times(0.25).toString(),
      BNBBonusInUSD: bigNumber(BBTAmount).times(0.3).times(0.25).times(v).toString()
    })
  }
  arr.sort((a, b) => b.BBTAmount - a.BBTAmount)
  return arr
}
